import React from 'react';
import technologyData from '../../JsonFiles/Technologies.json';
import './TechStack.css';
import { Link as RouterLink } from 'react-router-dom';

// Custom Link component to handle scroll behavior
const Link = ({ to, children, ...rest }) => {
  const handleClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    <RouterLink to={to} onClick={handleClick} {...rest}>
      {children}
    </RouterLink>
  );
};

const TechStack = ({ id }) => {
  const webDevelopmentData = technologyData.filter(technology => technology.field === "Web Development");
  const mobileApplicationData = technologyData.filter(technology => technology.field === 'Mobile Application');
  const databaseAndApisData = technologyData.filter(technology => technology.field === "Databases & Api's");

  return (
    <div id={id} className="TechStack-container">
      <h1 className="TechStack-title">Tech Stack</h1>
      <p className="TechStack-description">These are the tools we use to reach the optimal software solution.</p>
      <div className='contain-card'>
        <h2 className="category-header">Web Development</h2>
        <div className="TechStack-cards">
          {webDevelopmentData.map((technology, index) => (
            <Link to={`/technologies/${technology.id}`} key={index} className="TechStack-card">
              <h2>{technology.title}</h2>
              <img src={technology.icon} alt={technology.title} className="Icon-tech" />
            </Link>
          ))}
        </div>
        
        {/* Header for Mobile Application category */}
        <h2 className="category-header">Mobile Application</h2>
        <div className="TechStack-cards">
          {mobileApplicationData.map((technology, index) => (
            <Link to={`/technologies/${technology.id}`} key={index} className="TechStack-card">
              <h2>{technology.title}</h2>
              <img src={technology.icon} alt={technology.title} className="Icon-tech" />
            </Link>
          ))}
        </div>
        
        {/* Header for Databases & API's category */}
        <h2 className="category-header">Databases & API's</h2>
        <div className="TechStack-cards">
          {databaseAndApisData.map((technology, index) => (
            <Link to={`/technologies/${technology.id}`} key={index} className="TechStack-card">
              <h2>{technology.title}</h2>
              <img src={technology.icon} alt={technology.title} className="Icon-tech" />
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}

export default TechStack;
