import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import './Footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faLinkedin, faGithub, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons'; // Import the necessary icons
import servicesData from '../../JsonFiles/Services.json';
import ProjectData from "../../JsonFiles/Projects.json";
import IndustriesData from "../../JsonFiles/Industries.json";



// Custom Link component to handle scroll behavior
const Link = ({ to, children, ...rest }) => {
  const handleClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    <RouterLink to={to} onClick={handleClick} {...rest}>
      {children}
    </RouterLink>
  );
};

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-column">
          <h3><Link to="/projects">Portfolio</Link></h3>
          <ul>
            {ProjectData.map(project => (
              <li key={project.id}><Link to={`/projects/${project.id}`}>{project.title}</Link></li>
            ))}
          </ul>
        </div>

        <div className="footer-column">
          <h3><Link to="/services">Services</Link></h3>
          <ul>
            {servicesData.map(service => (
              <li key={service.id}><Link to={`/services/${service.id}`}>{service.title}</Link></li>
            ))}
            <li>
              <button className="btn">
                <Link to="/contact">Free Estimate</Link>
              </button>
            </li>
          </ul>
        </div>

        <div className="footer-column">
          <h3><Link to="/industries">Industries</Link></h3>
          <ul>
            {IndustriesData.map(industry => (
              <li key={industry.id}><Link to={`/industries/${industry.id}`}>{industry.title}</Link></li>
            ))}
          </ul>
        </div>

        <div className="footer-column">
          <h3><Link to="/about">About Us</Link></h3>
          <ul>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/about">About</Link></li>
            <li><Link to="/services">Services</Link></li>
            <li><Link to="/contact">Contact</Link></li>
            <li><Link to="/about">Career</Link></li>
          </ul>
        </div>

        <div className="footer-column">
          <h3>Social Media</h3>
          <ul>
            <li><FontAwesomeIcon icon={faFacebook} /> Facebook</li>
            <li><FontAwesomeIcon icon={faLinkedin} /> LinkedIn</li>
            <li><FontAwesomeIcon icon={faGithub} /> GitHub</li>
            <li><FontAwesomeIcon icon={faTwitter} /> Twitter</li>
            <li><FontAwesomeIcon icon={faInstagram} /> Instagram</li>
          </ul>
        </div>

        <div className="location-card">
          <h3><Link to="/about">Innotech Consultant</Link></h3>
          <p>Email: consultantinnotech@gmail.com</p>
          <a href="https://www.google.com/maps/place/Jai+Tulja+Bhavani+Complex,+Bhosari+Telco+Rd,+MIDC,+Bhosari,+Pimpri-Chinchwad,+Maharashtra+411026/@18.6251308,73.8337948,17z/data=!4m6!3m5!1s0x3bc2b87ea66348b5:0x6275c4f614780adb!8m2!3d18.6251257!4d73.8363697!16s%2Fg%2F11b6_d_nzk!5m1!1e2?entry=ttu" target="_blank" rel="noopener noreferrer">
            <p>Address: Office No 43, Pesh, Jai Tulja Bhavani Complex, Telco Rd, MIDC, Bhosari, Pune, Pimpri-Chinchwad, 411026</p>
          </a>
          <p>Phone: +91 7738899255</p>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; {new Date().getFullYear()} Innotech Consultant. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
