import React from 'react';
import { useParams, Link } from 'react-router-dom';
import pagesData from '../../JsonFiles/Projects.json';
import "./Projects.css"; // Import the CSS file
import IntroTemplate from '../../components/IntroTemplate/IntroTemplate';
import TechStack from '../../components/TechStack/TechStack';
import GetInTouch from '../../components/GetInTouch/GetInTouch.js';
import CaseStudies from '../../components/CaseStudies/CaseStudies.js';
import CardSliderWORK from "../../components/CardSliderWORK/CardSliderWORK.js"
import Faqs from '../../components/Faqs/Faqs.js';
import Bulleten from '../../components/Bulleten/Bulleten.js';

function ProjectsListPages() {
  return (
    <div>
      <h1 className='project-heading'>
  <span className="innotech">Innotech</span> projects we put into action
</h1>

      <div className="card-deck">
        {pagesData.map(page => (
          <ProjectCard key={page.id} project={page} />
        ))}
      </div>
    </div>
  );
}

function ProjectCard({ project }) {
  return (
    
    <div className="card-project">
      <Link to={`/projects/${project.id}`}>
      <div className="card-body">
        <img src={project.photo} alt="" className="project-image"></img>
        <p className="industry">{project.industry}</p>
        <h5 className="card-title">{project.title}</h5>
        
        <p className="card-text">{project.description}</p>
        <p className='discover-case'>Discover The Case</p>

      </div>
      </Link>
    </div>
    
  );
}

function ProjectsPage() {
  const { id } = useParams();
  const page = pagesData.find(page => page.id === parseInt(id));

  if (!page) {
    return <div>Page not found</div>;
  }

  return (
    <div>
      <IntroTemplate title={page.title} description={page.description} image={page.photo}/>
      <CardSliderWORK data={page.card} />
      <Bulleten data={page.advantages} title={page.title}/>
      <TechStack/>
      <CaseStudies/>
      <Faqs data={page.faqs}/>
      <GetInTouch/>
    </div>
  );
}

export { ProjectsPage, ProjectsListPages };
