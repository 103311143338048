
import React from 'react';
import { Link as RouterLink} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faBook, faMoneyBill, faHeartPulse, faPiggyBank, faDigitalTachograph, faBuilding, faLaptop, faTruck } from '@fortawesome/free-solid-svg-icons'; // Import the FontAwesome icons
import servicesData from '../../JsonFiles/Industries.json'; // Import the JSON data
import './Industries.css';


// Custom Link component to handle scroll behavior
const Link = ({ to, children, ...rest }) => {
  const handleClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    <RouterLink to={to} onClick={handleClick} {...rest}>
      {children}
    </RouterLink>
  );
};

const getIcon = (iconName) => {
    switch (iconName) {
      case 'faBook':
        return faBook;
      case 'faMoneyBill':
        return faMoneyBill;
      case 'faHeartPulse':
        return faHeartPulse;
      case 'faPiggyBank':
        return faPiggyBank;
      case 'faDigitalTachograph':
        return faDigitalTachograph;
      case 'faBuilding':
        return faBuilding;
      case 'faLaptop':
        return faLaptop;
      case 'faTruck':
        return faTruck;
      default:
        return faLaptop; // Default icon
    }
  };

  const Industries = ({ id }) => {
  // Slice the servicesData array to include only the first 6 items
  const displayedIndustries = servicesData.slice(0, 8);

  return (
    
    <div id={id} className="Industries-container">
      <h1 className="Industries-title">Industries We Serve</h1>
      <p className="Industries-description">We strive to be industry experts bringing business value and boosting your competitive power..</p>
      <div className="industriescard-container">
        {displayedIndustries.map(service => (
          <div key={service.id} className="induscard">
            <FontAwesomeIcon icon={getIcon(service.icon)} className="indusIcon" />
            <Link to={`/industries/${service.id}`}>
              <h3>{service.title} <FontAwesomeIcon icon={faArrowRight} className="arrow" /></h3>
            </Link>
            <p>{service.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Industries;