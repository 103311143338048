import React from 'react';
import { Link} from 'react-router-dom';
import './About.css'; // Import the CSS file
import GetInTouch from '../../components/GetInTouch/GetInTouch';
import Blog from '../../components/Blog/Blog';
import CaseStudies from '../../components/CaseStudies/CaseStudies';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteLeft, faQuoteRight} from '@fortawesome/free-solid-svg-icons';
import CardSlider from '../../components/carousel/Carousel';
import AboutIntro from '../../components/AboutIntro/AboutIntro';


function About() {


  return (
    <div className="about">
      <AboutIntro/>
      <CardSlider/>
      <CaseStudies/>
      <div className='quote-ceo'>
      <FontAwesomeIcon icon={faQuoteLeft} className='Quote-Left'/>
        <h3>We are dedicated to fostering a technological landscape that prioritizes social welfare</h3>
        <p>Pramod Diwedi, CEO at Innotech</p>
        <FontAwesomeIcon icon={faQuoteRight} className='Quote-Right'/>
        <div className="quotecall-now-button">
                    <Link to="/contact"><button className="quotecall-btn">Contact Now</button></Link>
                </div>
      </div>
      <Blog/>
      <GetInTouch/>
      
      
    </div>
  );
}

export default About;
