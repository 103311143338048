import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MainNavBar from './components/nav/Nav'; 
import Footer from './components/footer/Footer';
import Home from './pages/home/Home';
import About from './pages/about/About';
import Contact from './pages/contact/Contact';
import Career from './pages/career/Career';
import { TechPage } from './pages/technologies/Technologies'; 
import { IndusPage } from './pages/industries/Industries';
import { ServicesPage } from './pages/services/Services';
import { ProjectsListPages,ProjectsPage } from './pages/projects/Projects';
import Services from './components/Services/Services';
import TechStack from './components/TechStack/TechStack';
import Industries from './components/Industries/Industries';
import { BlogsPage,BlogListPages } from './pages/Blog/Blog';


// Define your routes
const AppRoutes = () => {
  return (
    <Router>
      <div>
        <MainNavBar /> 
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/technologies" element={<TechStack />} />
          <Route path="/technologies/:id" element={<TechPage />} />
          <Route path="/industries" element={<Industries />} />
          <Route path="/industries/:id" element={<IndusPage />} />
          <Route path="/services" element={<Services/>} />
          <Route path="/services/:id" element={<ServicesPage />} />
          <Route path="/projects" element={<ProjectsListPages/>} />
          <Route path="/projects/:id" element={<ProjectsPage />} />
          <Route path="/career" element={<Career />} />
          <Route path="/blog" element={<BlogListPages/>} />
          <Route path="/blog/:id" element={<BlogsPage/>} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
};

export default AppRoutes;
