import React from 'react';
// import { Link } from 'react-router-dom';
import './Contact.css'; // Import the CSS file
import image from "../../images/shiwanshu.jpeg";
import emailjs from 'emailjs-com';

function Contact() {
  const sendEmail = (e) => {
    e.preventDefault();

    // Extract form values
    const { name, email, about } = e.target.elements; // eslint-disable-line

    // Send email using EmailJS
    emailjs.sendForm('service_ystlmqj', 'template_pwxep1n', e.target, 'efnQ_-lOOJQ0YcFtY')
      .then((result) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });

    // Clear form fields
    e.target.reset();
  };

  return (
    <div className='ContactMain'>
      <div className="Contact-container">
        <div className='Contact-contain'>
          <h1 className="Contact-title">Start growing your business with us</h1>
          <p className='Contact-description'>Get ready to meet your next proactive tech partner. Tell us about your project, and we'll contact you within one business day, providing an action plan.</p>
          
          <form onSubmit={sendEmail}>
            <div className="form-group">
              <input type="text" id="name" name="name" placeholder='Name' required />
            </div>
            <div className="form-group">
              <input type="email" id="email" name="email" placeholder='Email' required />
            </div>
            <div className="form-group">
              <textarea id="about" name="about" rows="4" placeholder='About Your Project' required></textarea>
            </div>

            <p className='privacy'>By submitting, I agree to Innotech' Privacy Policy.</p>

            <div className="btn-container">
              <button type="submit" className="btn-Contact">LET'S TALK</button>
            </div>
          </form>
        </div>

        <div className='container-card'>
  <img src={image} className='Contact-img' alt="Contact" />
  <h3>Shiwanshu Pandey</h3>
  <p>Head of Partner Engagement</p>
  <div className="call-now-button">
    <a href="https://wa.me/+917738899255/?text=Hi" target="_blank" rel="noopener noreferrer">
      <button className="call-btn">Contact Now</button>
    </a>
  </div>
</div>

      </div>
    </div>
  );
}

export default Contact;
