import React, { useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import BlogCard from './BlogCard.js'; // Import the BlogCard component
import './Blog.css'; // Import the CSS file
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import BlogData from '../../JsonFiles/Blogs.json'; // Import the JSON data

const Blog = () => {
  const sliderRef = useRef(null);
  useEffect(() => {
    const interval = setInterval(() => {
      if (sliderRef.current) {
        sliderRef.current.slickNext();
      }
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <div className='Blog-container'>
      <h1 className="Blog-title">Check Out Our Blog</h1>
      <div className="Blog-carousel-wrapper">
        <div className="Blog-carousel-container">
          <Slider ref={sliderRef} {...settings}>
            {BlogData.map((Blog, index) => (
              <div key={index}>
                <BlogCard Blog={Blog} />
              </div>
            ))}
          </Slider>
        </div>
      </div>
      <div className="button-container">
        <p className='check-out'>Check out our Blog</p>
        <Link to="/blog" className='btn-case'>read more</Link>
      </div>
    </div>
  );
};

export default Blog;
