import React from 'react';
import './Bulleten.css'; // Import CSS for styling
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faCircle } from '@fortawesome/free-solid-svg-icons'; 


const Bulleten = ({ data, title }) => {
  return (
    <div>
        <h2 className='heading-Faqs'>{"Why Choose "+title+" For Your Project"}</h2>
        <div className='bulleten-container'>
      
      {data.map((bulleten, index) => (
        <div key={index}>
            {/* <FontAwesomeIcon
                icon={faCircle}
                className="circle-icon"
              /> */}
          <h3 className='Bulleten-title'>{bulleten.question}</h3>
          <p className='Bulleten-description'>{bulleten.answer}</p>
        </div>
      ))}
    </div>
    </div>
   
  );
};

export default Bulleten;
