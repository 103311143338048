import React, { useState } from 'react';
import './Carousel.css'; // Import CSS for styling
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
// import image from "../../images/quality.jpg"

// Sample card data
// Sample card data
const cards = [
  {
    title: 'Quality Assurance with Strike Force',
    description: 'Engage our elite team of senior developers, known as the Strike Force, to kick-start your project with impeccable quality. Within a month, they will establish the development environment, key processes, and project architecture. Subsequently, we recommend transitioning most team members to junior or mid-level developers, while senior developers remain as consultants. This approach ensures a cost-effective project without compromising on quality right from the outset.',
    image: '/images/quality.jpg',
  },
  {
    title: 'UX Audit Services',
    description: 'Our comprehensive UX audit uncovers user pain points and identifies barriers hindering the success of your solution. This approach is instrumental in reducing development costs and enhancing long-term ROI. Through the UX audit, we meticulously analyze your solutions design to optimize user flows and eliminate user frustrations and design inconsistencies.',
    image: '/images/uiAudit.jpg',
  },
  {
    title: 'Enhanced Security Measures',
    description: 'Leverage our trusted escrow partner to safeguard your investments as a neutral intermediary. Prior to commencing development, we establish mutually agreed terms with the escrow agent for releasing payments. To estimate your approximate escrow expenses, please consult with us.',
    image: '/images/security.jpg',
  },
  {
    title: 'Highly Motivated Team',
    description: 'By partnering with Innotech, you gain access to a passionate team of dedicated developers. Our dedicated Mental Health Officer closely monitors the well-being of each team member, ensuring high levels of happiness, motivation, and loyalty. Through regular employee satisfaction assessments, we mitigate turnover risks and avoid project delays associated with personnel changes.',
    image: '/images/motivated.jpg',
  },
];

const CardSlider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const goToCard = (index) => {
    setCurrentIndex(index);
  };

  const goToPrevCard = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? cards.length - 1 : prevIndex - 1));
  };

  const goToNextCard = () => {
    setCurrentIndex((prevIndex) => (prevIndex === cards.length - 1 ? 0 : prevIndex + 1));
  };

  return (
    <div>
      <h3 className='cardSlider-Heading'>What Difference Do We Offer?</h3>
    <div className="card-slider">
      <button className="slider-button left" onClick={goToPrevCard}>
        <FontAwesomeIcon icon={faChevronLeft} />
      </button>
      <div className="card-container">
      <div
  key={currentIndex}
  className="card-about active"
>
  <div className="left-section">
    <img src={cards[currentIndex].image} alt={cards[currentIndex].title}  className='img-slider'/>
  </div>
  <div className="right-section">
    <div className="card-content">
      <h2>{cards[currentIndex].title}</h2>
      <p>{cards[currentIndex].description}</p>
    </div>
  </div>
</div>



</div>

      <button className="slider-button right" onClick={goToNextCard}>
        <FontAwesomeIcon icon={faChevronRight} />
      </button>
      
    </div>
    <div className='dots-container'>
        <div className="dots">
        {cards.map((card, index) => (
          <span key={index} className={index === currentIndex ? 'dash active' : 'dash'} onClick={() => goToCard(index)} />
        ))}
      </div>
    </div>
    </div>
  );
  
};

export default CardSlider;
