import React from 'react';
import './Career.css'; // Import the CSS file
// import { Link } from 'react-router-dom';

import image1 from "../../images/About.png";

import GetInTouch from '../../components/GetInTouch/GetInTouch.js';


function Career() {

  

  return (
    <div>
      <div className="career-intro">
        <div className='careerintro-padding'>
        <h1 className="career-title">Grow your career with the ethical company!</h1>
        <p className="career-description">We always have interesting vacancies for you. Join our team!</p>
        </div>
      </div>

      <div className="vacancies-container">
      <div className='vacancies-image'>
        <img src={image1} className='vacancies-img' alt="" />
      </div>
      <div className='vacancies-contain'>
        <h1 className="vacancies-title">Our Vacancies</h1>
        <p className="vacancies-title2">Do you want to be a part of our team?</p>
        <p className='vacancies-description'>We offer competitive working conditions and compensation for outstanding candidates. So, share your contact data with us, and we’ll find a proper position for you.</p>
        {/* <Link to="/vacancies" className='btn-vacancies'>Talk to Us</Link> */}
        <a href="https://wa.me/+917738899255/?text=Hi" target="_blank" rel="noopener noreferrer" className='btn-vacancies'>Talk to Us</a>
      </div>
      
    </div>
    <GetInTouch></GetInTouch>
    </div>
    
  );
}

export default Career;

