import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import './Nav.css'; 
import './MobileNav.css'; // Import styles for MobileNav
import logo from '../../images/image1.png'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars,faAngleRight , faTimes} from '@fortawesome/free-solid-svg-icons'; 

const Link = ({ to, children, ...rest }) => {


  return (
    <RouterLink to={to} {...rest}>
      {children}
    </RouterLink>
  );
};

const MobileNav = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
    window.scrollTo(0, 0); // Scroll to the top of the page when menu is closed
  };
  
    return (
      <nav className="mobile-navbar sticky">
        <Link to="/" className="navbar-logo-link"> 
          <img src={logo} alt="Logo" className="navbar-logo" /> 
        </Link>
        <div className="mobile-menu-icon" onClick={toggleMenu}>
        <FontAwesomeIcon
    icon={isOpen ? faTimes : faBars}
    style={{
      transition: "transform 0.5s ease-in-out" // Add a transition effect to transform property
    }}
  />
  
        </div>
        <div className={`mobile-navbar-collapse ${isOpen ? 'show' : ''}`}>
          <ul className="mobile-navbar-nav">
            <li className="mobile-nav-item">
              <Link to="/projects" className="mobile-nav-link" onClick={closeMenu}>Projects</Link>
              <FontAwesomeIcon icon={faAngleRight} />
            </li>
            <li className="mobile-nav-item">
              <Link to="/services" className="mobile-nav-link" onClick={closeMenu}>Services</Link>
              <FontAwesomeIcon icon={faAngleRight} />
            </li>
            <li className="mobile-nav-item">
              <Link to="/industries" className="mobile-nav-link" onClick={closeMenu}>Industries</Link>
              <FontAwesomeIcon icon={faAngleRight} />
            </li>
            <li className="mobile-nav-item">
              <Link to="/technologies" className="mobile-nav-link" onClick={closeMenu}>Technologies</Link>
              <FontAwesomeIcon icon={faAngleRight} />
            </li>
            <li className="mobile-nav-item">
              <Link to="/about" className="mobile-nav-link" onClick={closeMenu}>About Us</Link>
            </li>
            <li className="mobile-nav-item">
              <Link to="/team" className="mobile-nav-link" onClick={closeMenu}>Blog</Link>
            </li>
            <li className="mobile-nav-item">
              <Link to="/career" className="mobile-nav-link" onClick={closeMenu}>Career</Link>
            </li>
            <li className="mobile-nav-item">
              <Link to="/contact" className="mobile-nav-link" onClick={closeMenu}>Contact Us</Link>
            </li>
          </ul>
        </div>
      </nav>
    );
  };


  export default MobileNav;