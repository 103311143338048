import React from 'react';
import Intoduction from '../../components/introduction/Introduction';
import Services from '../../components/Services/Services';
import Testimonial from '../../components/Testimonial/Testimonial';
import TechStack from '../../components/TechStack/TechStack';
import Industries from '../../components/Industries/Industries';
import CaseStudies from '../../components/CaseStudies/CaseStudies'
import About from '../../components/About/About';
import Talk from '../../components/Talk/Talk';
import Blog from '../../components/Blog/Blog';
import "./Home.css";


function Home() {
  return (
    <div>
      <Intoduction/>
      <Services id="services-section"/>
      <Testimonial/>
      <TechStack id="technology-section"/>
      <Industries id="industries-section"/>
      <CaseStudies/>
      <About/>
      <Talk/>
      <Blog/>
    </div>
  );
}


export default Home;
