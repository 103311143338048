import React from 'react';
import './GetInTouch.css'; 
import image2 from "../../images/shiwanshu.png";
import emailjs from 'emailjs-com';

function GetInTouch() {
    const sendEmail = (e) => {
        e.preventDefault();
    
        // Extract form values
        const { name, email, vacancies } = e.target.elements; // eslint-disable-line
    
        // Send email using EmailJS
        emailjs.sendForm('service_ystlmqj', 'template_pwxep1n', e.target, 'efnQ_-lOOJQ0YcFtY')
          .then((result) => {
            console.log(result.text);
          }, (error) => {
            console.log(error.text);
          });
    
        // Clear form fields
        e.target.reset();
    };

    return (
        <div className='GetInTouch-container'>
            <div className='GetInTouch-contain'>
                <h1 className="GetInTouch-title">Get In Touch</h1>
                
                <form onSubmit={sendEmail}>
                    <div className="GetInTouch-group">
                        <input type="text" id="name" name="name" placeholder='Name' required />
                    </div>
                    <div className="GetInTouch-group">
                        <input type="email" id="email" name="email" placeholder='Email' required />
                    </div>
                    <div className="GetInTouch-group">
                        <input id="phone" name="phone" rows="4" placeholder='Phone / Whatsapp' required></input>
                    </div>

                    <p className='GetInTouch-privacy'>By submitting, I agree to Innotech' Privacy Policy.</p>

                    <div className="btnget-container">
                        <button type="submit" className="btn-GetInTouch">LET'S TALK</button>
                    </div>
                </form>
            </div>
            <div className='careercontainer-card'>
                <img src={image2} className='careerTalk-img' alt="Talk" />
                <h3>Shiwanshu Pandey</h3>
                <div className="careercall-now-button">
                    <a href="https://wa.me/+917738899255/?text=Hi" target="_blank" rel="noopener noreferrer">
                        <button className="careercall-btn">Contact Now</button>
                    </a>
                </div>
            </div>
        </div>
    );
}

export default GetInTouch;
