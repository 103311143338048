import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteLeft, faQuoteRight, faCircle} from '@fortawesome/free-solid-svg-icons'; // Import the down arrow icon
import "./Introduction.css";

function Introduction() {

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = [
    "/images/introimage1.jpg",
    "/images/introimage2.jpg",
    "/images/introimage3.jpeg",
    // Add more image URLs here
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex(prevIndex => (prevIndex + 1) % images.length);
    }, 5000);

    return () => clearInterval(interval);
  }, [images.length]); // Include images.length in the dependency array

  return (
    <div className="introduction-container">
      <div className="content">
        <h3>
          <FontAwesomeIcon icon={faQuoteLeft} />  Lead with purpose<span>  </span>  
          <FontAwesomeIcon icon={faCircle} style={{ fontSize: '0.5em', margin: '0 5px' }} /> <span>  </span>   excellence will follow {' '}
          <FontAwesomeIcon icon={faQuoteRight} />
        </h3>
        <h1 className='intro-title'>Bring Your Business Online With IT Company Services</h1>
        <p>Multiple companies have transformed their concepts into successful products with our assistance. Our exceptional web and mobile projects encompass a wide spectrum, ranging from MVPs and low-code initiatives to cloud-based and data-driven solutions.</p>
        <div className="btn-container">
          <button className="btn">
            <Link to="/contact">Free Estimate</Link>
          </button>
        </div>
        
      </div>
      <div className="image-container" style={{ position: 'relative' }}>
        <img src={images[currentImageIndex]} alt="Logo" className="into-logo" />
        
      </div>
    </div>
  );
}

export default Introduction;
