import React from 'react';
import './CaseStudiesCard.css'; // Import the CSS file
import { Link as RouterLink } from 'react-router-dom';
// Custom Link component to handle scroll behavior
const Link = ({ to, children, ...rest }) => {
  const handleClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    <RouterLink to={to} onClick={handleClick} {...rest}>
      {children}
    </RouterLink>
  );
};

const CaseStudiesCard = ({ CaseStudies }) => {
  return (
    <div className="CaseStudies-card">
      <a href={CaseStudies.link} target="_blank" rel="noopener noreferrer"> {/* Add anchor tag */}
        <img src={CaseStudies.photo} alt={CaseStudies.name} className="CaseStudies-image" />
      </a> 
      
      <div className='CaseStudiesCard-details'>
      <Link to={`/projects/${CaseStudies.id}`} className="CaseStudies-card-link">
        <h3 className='CaseStudiesCard-title'>{CaseStudies.title}</h3>
        <p className='CaseStudiesCard-industry'>{CaseStudies.industry}</p>
        <p className='CaseStudiesCard-description'>{CaseStudies.description}</p>
          <p className='read-more'>read more about this project</p>
        </Link>
      </div>
    </div>
  );
};

export default CaseStudiesCard;
