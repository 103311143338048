import React from 'react';
import './IntroTemplate.css'; // Import the CSS file
import { Link } from 'react-router-dom';

function IntroTemplate({ title, description, image }) {
  return (
    <div>
<div className="IntroTemplate-intro" style={{backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.9)), url(${image})`}}>

        <div className='careerintro-padding'>
          <h1 className="IntroTemplate-title">{title}</h1>
          
          <div className='line-introtemplate'></div>
          <p className="IntroTemplate-description">{description}</p>
          <Link to="/contact" className='btn-introtemplate'>ASK US QUESTIONS</Link>
        </div>
      </div>
    </div>
  );
}

export default IntroTemplate;

