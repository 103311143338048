import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faLaptopCode, faMobileAlt, faSearch, faChartBar, faLink, faScrewdriverWrench } from '@fortawesome/free-solid-svg-icons'; // Import the FontAwesome icons
import servicesData from '../../JsonFiles/Services.json'; // Import the JSON data
import './Services.css';
import { Link as RouterLink } from 'react-router-dom';
// Custom Link component to handle scroll behavior
const Link = ({ to, children, ...rest }) => {
  const handleClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    <RouterLink to={to} onClick={handleClick} {...rest}>
      {children}
    </RouterLink>
  );
};
// Icon mapping function
const getIcon = (iconName) => {
  switch (iconName) {
    case 'faLaptopCode':
      return faLaptopCode;
    case 'faMobileAlt':
      return faMobileAlt;
    case 'faLink':
      return faLink;
    case 'faSearch':
      return faSearch;
    case 'faChartBar':
      return faChartBar;
    case 'faScrewdriverWrench':
      return faScrewdriverWrench;
    default:
      return null;
  }
};

const Services = ({ id }) => {
  // Slice the servicesData array to include only the first 6 items
  const displayedServices = servicesData.slice(0, 6);

  return (
    <div id={id} className="Services-container">
      <h1 className="Services-title">Solutions We Provide<FontAwesomeIcon icon={faScrewdriverWrench} className='title-icon'></FontAwesomeIcon></h1>
      <p className="Services-description">We Specialize In Crafting Top-Tier Digital Solutions And Products, Accompanied By An Extensive Array Of Associated Professional Services. Our Commitment Is To Deliver Unparalleled Service Excellence To Our Clients, Ensuring World-Class Quality In Every Aspect Of Our Offerings.</p>
      <div className="cardthis-container">
        {displayedServices.map(service => (
          <div key={service.id} className="card">
            <FontAwesomeIcon icon={getIcon(service.icon)} className="icon" />
            <Link to={`/services/${service.id}`}>
              <h3>{service.title} <FontAwesomeIcon icon={faArrowRight} className="arrow" /></h3>
            </Link>
            <p>{service.descriptionsmall}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Services;



