import React from 'react';
import { useParams} from 'react-router-dom';
import pagesData from '../../JsonFiles/Technologies.json';
import CaseStudies from '../../components/CaseStudies/CaseStudies';
import Testimonial from '../../components/Testimonial/Testimonial';
import IntroTemplate from '../../components/IntroTemplate/IntroTemplate';
import GetInTouch from '../../components/GetInTouch/GetInTouch.js';
import CardSliderWORK from "../../components/CardSliderWORK/CardSliderWORK.js"
import Faqs from '../../components/Faqs/Faqs.js';
import Bulleten from '../../components/Bulleten/Bulleten.js';



function TechPage() {
  const { id } = useParams();
  const page = pagesData.find(page => page.id === parseInt(id));

  if (!page) {
    return <div>Page not found</div>;
  }

  return (
    <div>
       <IntroTemplate
  title={
    <div>
      {page.title}{' '}
      <span style={{ color: '#d62c2c' }}>Innotech</span>
      {' Development Company ;'}
    </div>
  }
  description={page.description}
  image={page.image}
/>

<CardSliderWORK data={page.card} />
<Bulleten data={page.advantages} title={page.title}/>
      <CaseStudies/>
      <Faqs data={page.faqs} />
      <Testimonial/>
      <GetInTouch/>
      
    </div>
  );
}

export { TechPage };
