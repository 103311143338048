import React, { useEffect, useState } from 'react';
import './AboutIntro.css'; // Import the CSS file
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';



function AboutIntro() {

  const [number1, setNumber1] = useState(0);
  const [number2, setNumber2] = useState(0);
  const [number3, setNumber3] = useState(0);



  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = [
    "/images/About.png",
    // "/images/shiwanshu.jpeg",
    "/images/file.enc",
    // Add more image URLs here
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex(prevIndex => (prevIndex + 1) % images.length);
    }, 7000);

    return () => clearInterval(interval);
  }, [images.length]); // Include images.length in the dependency array

  useEffect(() => {
    const interval = setInterval(() => {
      // Increase numbers up to a desired value
      const Activeprojects = 10; 
      const NetPromoterScore = 40;
      const ActiveSocialProject = 2;
      setNumber1(prevNumber => (prevNumber < Activeprojects  ? prevNumber + 1 : Activeprojects));
      setNumber2(prevNumber => (prevNumber < NetPromoterScore ? prevNumber + 1 : NetPromoterScore));
      setNumber3(prevNumber => (prevNumber < ActiveSocialProject ? prevNumber + 1 : ActiveSocialProject));
    }, 120); // Change interval as needed

    return () => clearInterval(interval);
  }, []);


  return (
  <div className="aboutIntro-container">
    <div className="aboutIntro-contain">
        <div className='aboutintro-content'>
        <h3>
           Lead with purpose<span>  </span>  
          <FontAwesomeIcon icon={faCircle} style={{ fontSize: '0.5em', margin: '0 5px', color: "#d62c2c" }} /> <span>  </span> excellence will follow {' '}
          
        </h3>
        <div className="line" />
      <p className="aboutIntro-description">Innotech is an ethical web and mobile development partner based in Pune and represented in Delhi, 
      Mumbai and in More state soon.</p>
      <p className='aboutIntro-description2'>In 2020, Pramod Diwiedi, CEO, and Shiwanshu Pandey, CTO, assembled a team at Innotech with the aim of revolutionizing the software industry. Our objective at Innotech has consistently been to foster ethical practices in the tech sphere while leveraging technology for societal benefit. Over the years, more than 10 companies have collaborated with us to transform their concepts into profitable products. We continuously invite partners to join us in our endeavor to drive forward impactful initiatives, both socially and commercially.</p>
      
      <div className="numbers-store">
      <div className="numbers-container">
        <span>{number1}+</span><p>active clients</p>
        
      </div>
      <div className="numbers-container">
      <span>{number2}</span><p>Net Promoter Score</p>
        
      </div>
      <div className="numbers-container">
      <span>{number3}</span><p>Active Social Projects</p>
        
      </div>
      </div>
      
      
        
        </div>
        <div className="aboutimage-container" style={{ position: 'relative' }}>
        <img src={images[currentImageIndex]} alt="Logo" className="aboutinto-logo" />
      </div>
      </div>
      </div> 

);
}

export default AboutIntro;