
import React, { useRef, useEffect } from 'react';
import TestimonialCard from './TestimonialCard'; // Import the TestimonialCard component
import './Testimonial.css'; // Import the CSS file
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import testimonialsData from '../../JsonFiles/Testimonial.json'; // Import the JSON data

const Testimonial = () => {
  const sliderRef = useRef(null);

  useEffect(() => {
    const interval = setInterval(() => {
      if (sliderRef.current) {
        sliderRef.current.slickNext();
      }
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <div className='testimonial-container'>
      <h1 className="testimonial-title">Our Partner Testimonials</h1>
      <div className="testimonial-carousel-wrapper">
        <div className="testimonial-carousel-container">
          <Slider ref={sliderRef} {...settings}>
            {testimonialsData.map((testimonial, index) => (
              <div key={index}>
                <TestimonialCard testimonial={testimonial} />
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
