import React from 'react';
import { useParams, Link } from 'react-router-dom';
import pagesData from '../../JsonFiles/Blogs.json';
import "./Blog.css"; // Import the CSS file

// Import BlogCard component
function BlogCard({ Blog }) {
  return (
    <div className="card-Blog">
      <Link to={`/Blogs/${Blog.id}`}>
        <div className="card-body">
          <img src={Blog.photo} alt="" className="Blog-image"></img>
          <p className="industry">{Blog.industry}</p>
          <h5 className="card-title">{Blog.title}</h5>
          <p className="card-text">{Blog.description}</p>
          <p className='discover-case'>Discover The Case</p>
        </div>
      </Link>
    </div>
  );
}

// BlogListPages component
function BlogListPages() {
  return (
    <div>
      <h1 className='project-heading'>
        <span className="innotech">Innotech</span> blog about
web and app development
      </h1>
  
      <div className="card-deck">
        {pagesData.map(page => (
          <BlogCard key={page.id} Blog={page} />
        ))}
      </div>
    </div>
  );
}

// BlogsPage component
function BlogsPage() {
  const { id } = useParams();
  const page = pagesData.find(page => page.id === parseInt(id));

  if (!page) {
    return <div>Page not found</div>;
  }

  return (
    <div>
      <h1>{page.title}</h1>
      <p>{page.description}</p>
    </div>
  );
}

export { BlogsPage, BlogListPages };
