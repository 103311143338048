import React, { useState } from 'react';
import './CardSliderWORK.css'; // Import CSS for styling
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

const CardSlider = ({ data }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const goToCard = (index) => {
    setCurrentIndex(index);
  };

  const goToPrevCard = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? data.length - 1 : prevIndex - 1));
  };

  const goToNextCard = () => {
    setCurrentIndex((prevIndex) => (prevIndex === data.length - 1 ? 0 : prevIndex + 1));
  };

  // Calculate the indices of the three cards to be displayed
  const prevIndex = currentIndex === 0 ? data.length - 1 : currentIndex - 1;
  const nextIndex = currentIndex === data.length - 1 ? 0 : currentIndex + 1;

  return (
    <div>
      <h3 className='cardSliderwork-Heading'>What Services Do We Offer?</h3>
      <div className="cardSliderwork-slider">
        <button className="cardSliderwork-button left" onClick={goToPrevCard}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>
        <div className="cardSliderwork-container">
          {/* Render the three cards */}
          <div className="cardSliderwork-about active">
            <div className="cardSliderworkright-section">
              <div className="cardSliderwork-content">
                <h2>{data[prevIndex].title}</h2>
                <p>{data[prevIndex].description}</p>
              </div>
            </div>
          </div>
          <div className="cardSliderwork-about active">
            <div className="cardSliderwork-section">
              <div className="cardSliderwork-content">
                <h2>{data[currentIndex].title}</h2>
                <p>{data[currentIndex].description}</p>
              </div>
            </div>
          </div>
          <div className="cardSliderwork-about active">
            <div className="cardSliderworkright-section">
              <div className="cardSliderwork-content">
                <h2>{data[nextIndex].title}</h2>
                <p>{data[nextIndex].description}</p>
              </div>
            </div>
          </div>
        </div>
        <button className="cardSliderwork-button right" onClick={goToNextCard}>
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
      </div>
      <div className='dots-container'>
        <div className="dots">
          {data.map((card, index) => (
            <span key={index} className={index === currentIndex ? 'dash active' : 'dash'} onClick={() => goToCard(index)} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default CardSlider;
