import React, { useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import CaseStudiesCard from './CaseStudiesCard.js'; // Import the CaseStudiesCard component
import './CaseStudies.css'; // Import the CSS file
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import CaseStudiesData from '../../JsonFiles/Projects.json'; // Import the JSON data

const CaseStudies = () => {
  const sliderRef = useRef(null);
  useEffect(() => {
    const interval = setInterval(() => {
      if (sliderRef.current) {
        sliderRef.current.slickNext();
      }
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <div className='CaseStudies-container'>
      <h1 className="CaseStudies-title">Check Out Our Case Studies</h1>
      <div className="CaseStudies-carousel-wrapper">
        <div className="CaseStudies-carousel-container">
          <Slider ref={sliderRef} {...settings}>
            {CaseStudiesData.map((CaseStudies, index) => (
              <div key={index}>
                <CaseStudiesCard CaseStudies={CaseStudies} />
              </div>
            ))}
          </Slider>
        </div>
      </div>
      <div className="button-container">
        <p className='check-out'>Check out our case studies</p>
        <Link to="/projects" className='btn-case'>read more</Link>
      </div>
    </div>
  );
};

export default CaseStudies;
