import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import './Nav.css'; 
import './MobileNav.css'; // Import styles for MobileNav
import logo from '../../images/image1.png'; 
import servicesData from '../../JsonFiles/Services.json';
import TechnologyData from "../../JsonFiles/Technologies.json";
import ProjectData from "../../JsonFiles/Projects.json";
import IndustriesData from "../../JsonFiles/Industries.json";
import MobileNav from './MobileNav';


const Link = ({ to, children, ...rest }) => {


  const handleClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    <RouterLink to={to} onClick={handleClick} {...rest}>
      {children}
    </RouterLink>
  );
};

const NavBar = () => {
  return (
    <nav className="navbar sticky">
      <Link to="/">
        <img src={logo} alt="Logo" className="logo-nav" /> 
      </Link>
      <div className="nav-left">
        <ul className="navbar-nav">
          <li className="nav-item dropdown">
            <Link to="/" className="nav-link">Home</Link>
          </li>
          <li className="nav-item dropdown">
            <Link to="/Projects" className="nav-link">Projects</Link>
            <div className="dropdown-menu">
              {ProjectData.map(project => (
                <Link key={project.id} to={`/projects/${project.id}`} className="dropdown-item">{project.title}</Link>
              ))}
            </div>
          </li>
          <li className="nav-item dropdown">
            <Link to="/services" className="nav-link">Services</Link>
            <div className="dropdown-menu">
              {servicesData.map(service => (
                <Link key={service.id} to={`/services/${service.id}`} className="dropdown-item">{service.title}</Link>
              ))}
            </div>
          </li>
          <li className="nav-item dropdown">
            <Link to="/industries" className="nav-link">Industries</Link> 
            <div className="dropdown-menu">
              {IndustriesData.map(industry => (
                <Link key={industry.id} to={`/industries/${industry.id}`} className="dropdown-item">{industry.title}</Link>
              ))}
            </div>
          </li>
          <li className="nav-item dropdown">
            <Link to="/technologies" className="nav-link">Technologies</Link> 
            <div className="dropdown-menu">
              {/* Limiting to 8 technology titles */}
              {TechnologyData.slice(0, 8).map((technology) => (
                <Link key={technology.id} to={`/technologies/${technology.id}`} className="dropdown-item">
                  {technology.title}
                </Link>
              ))}
              {/* See More link */}
              <Link to="/technologies" className="dropdown-item see-more">
                See More
              </Link>
            </div>
          </li>
          <li className="nav-item">
            <Link to="/about" className="nav-link">About Us</Link>
          </li>
          <li className="nav-item">
            <Link to="/blog" className="nav-link">Blog</Link> {/* Updated link text to "Blog" */}
          </li>
          <li className="nav-item">
            <Link to="/career" className="nav-link">Career</Link>
          </li>
        </ul>
      </div>
      <div className="nav-right"> {/* Container for right-aligned link */}
        <button className="btn"> 
          <Link to="/contact">Contact Us</Link>
        </button>
      </div>
    </nav>
  );
};




// Main component that decides which navbar to render based on screen size
const MainNavBar = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1080);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1080);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return isMobile ? <MobileNav /> : <NavBar />;
};

export default MainNavBar;
