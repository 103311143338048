import React from 'react';
import './BlogCard.css'; // Import the CSS file
import { Link } from 'react-router-dom'; // Import the Link component

const BlogCard = ({ Blog }) => {
  return (
    <div className="Blog-card">
      <a href={Blog.link} target="_blank" rel="noopener noreferrer"> {/* Add anchor tag */}
        <img src={Blog.photo} alt={Blog.name} className="BlogCard-img" />
      </a> 
      
      <div className='BlogCard-details'>
      <Link to={`/blog/${Blog.id}`} className="Blog-card-link">
        <h3 className='BlogCard-title'>{Blog.title}</h3>
        <p className='BlogCard-industry'>{Blog.industry}</p>
        <p className='BlogCard-description'>{Blog.description}</p>
          <p className='read-more'>read more about this project</p>
        </Link>
      </div>
    </div>
  );
};

export default BlogCard;
