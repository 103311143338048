import React from 'react';
import { Link } from 'react-router-dom';
import './About.css'; // Import the CSS file
import image from "../../images/About.png";

function About() {
  return (
    <div className="about-container">
      <div className='about-contain'>
        <h1 className="about-title">About Us As Web Development And Digital Marketing Company</h1>
        <p className="about-title2">Innotech was founded on the conviction that the technology sector could strive for greater excellence.</p>
        <p className='about-description'>At Innotech, leadership is embodied by Pramod Diwedi, serving as CEO, and Shiwanshu Pandey, serving as CTO. Our journey began in 2020 when we jointly established Innotech, driven by our conviction that we could significantly impact the industry. Our mission is to ensure ethical conduct in the tech sphere, as we continue to innovate and excel.</p>
        <Link to="/about" className='btn-about'>LEARN ABOUT US</Link>
      </div>
      <div className='about-image'>
        <img src={image} className='about-img' alt="" />
      </div>
    </div>
  );
}

export default About;
