import React from 'react';
import { useParams } from 'react-router-dom';
import pagesData from '../../JsonFiles/Services.json';
import IntroTemplate from '../../components/IntroTemplate/IntroTemplate';
import TechStack from '../../components/TechStack/TechStack';
import GetInTouch from '../../components/GetInTouch/GetInTouch.js';
import CardSliderWORK from "../../components/CardSliderWORK/CardSliderWORK.js";
import Faqs from '../../components/Faqs/Faqs.js';
import Bulleten from '../../components/Bulleten/Bulleten.js';


function ServicesPage() {
  const { id } = useParams();
  const page = pagesData.find(page => page.id === parseInt(id));

  if (!page) {
    return <div>Page not found</div>;
  }

  return (
    <div>
      <IntroTemplate
  title={(
    <span>
      {page.title + " "}
      <span style={{ color: 'white' }}>services from </span>
      <span style={{ color: '#d62c2c'}}>Innotech</span>
    </span>
  )}
  description={page.description}
  image={page.image}
/>
<CardSliderWORK data={page.card} />
<Bulleten data={page.advantages} title={page.title}/>
      <TechStack/>
      <Faqs data={page.faqs}/>
      <GetInTouch/>
    </div>
  );
}

export { ServicesPage};
